import { Inject, Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { IAuthService } from "../interfaces/i-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Inject('IAuthService') private authService: IAuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string | undefined = this.authService.getJwtToken();

    if (token && !request.url.includes('graph.facebook.com')) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + token),
      });
    }

    return next.handle(request);
  }
}
