<div class="modal-header">  
    <h3 class="modal-title">
    <i class="{{config.headerIcon}}"></i>
        {{config.headerText}}
    </h3>   
</div>

<div *ngIf="config.bodyHtml" class="modal-body" [innerHtml]="config.bodyHtml"></div> 
<div *ngIf="config.bodyText" class="modal-body"> 
    {{config.bodyText}}
</div> 

<div class="modal-footer">
    <ng-container *ngFor="let button of config.buttons">
        <button type="button" class="btn btn-{{button.type || 'default'}} btn-sm" (click)="do(button.id)">
            <i class="{{button.icon}}"></i> {{button.label}}
        </button>
    </ng-container>
</div>
