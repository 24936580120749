import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartoafEnv } from '../models/env';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private dynamicEnv: CartoafEnv;

  constructor() { }
  /** Setter for environment variable */
  setEnvironment(env: Observable<any>){
    env.subscribe(data => {
      this.dynamicEnv = { ...data}
    });
  }
  /** Getter for environment variable */
  get environment() {
    return this.dynamicEnv || environment;
  }
}
