import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from './core/services/environment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private environmentService: EnvironmentService,
    private title: Title
  ) {}
  
  ngOnInit() {
    this.title.setTitle(this.environmentService.environment.welcome.message?.replace(/<.*?>/g, '') || 'CartoAF');
  }
}
