import { Injectable } from '@angular/core';
import { EMPTY, Observable, timer } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, delayWhen, retryWhen, switchAll, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { EventMessage } from '../models/notifications';
import { EnvironmentService } from './environment.service';

const RECONNECT_INTERVAL = 3000;

export interface Message {
    source: string;
    content: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventsWsService {
  private socket$: WebSocketSubject<unknown>;
  private messagesSubject$ = new Subject();
  public messages$: Observable<EventMessage> = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));

  constructor(
    private env: EnvironmentService
  ) {}

  /**
   * Creates a new WebSocket subject and send it to the messages subject
   * @param cfg if true the observable will be retried.
   */
  public connect(cfg: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      this.messages$ = this.socket$.pipe(cfg.reconnect ? this.reconnect : o => o,
        tap({
          error: error => console.log(error),
        }), catchError(_ => EMPTY))
    }
  }

  /**
   * Retry a given observable by a time span
   * @param observable the observable to be retried
   */
  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(retryWhen(errors => errors.pipe(tap(val => console.log('[CartoAf Events] Try to reconnect')),
      delayWhen(_ => timer(RECONNECT_INTERVAL)))));
  }

  close() {
    this.socket$.complete();
    this.socket$.unsubscribe();
    this.socket$ = undefined;
  }

  sendMessage(msg: any) {
    this.socket$.next(msg);
  }

  /**
   * Return a custom WebSocket subject which reconnects after failure
   */
  private getNewWebSocket() {
    return webSocket({
      url: this.env.environment.wsServer,
      openObserver: {
        next: () => {
          console.log('[CartoAf Events]: connection ok ' + new Date().toLocaleString());
        }
      },
      closeObserver: {
        next: () => {
          console.log('[CartoAf Events]: connection closed ' + new Date().toLocaleString());
          this.socket$ = undefined;
          this.connect({ reconnect: true });
        }
      }
    });
  }
}
