import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ParamWidget } from '../models/widgets';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  resolveObjectName(object: any) {

    const keys = ["actionname","class_name","theme_name","app_name","prop_column","__id"];
    let name = '';

    keys.forEach((key) => {
        if (name.length==0 && key in object) {
            name = object[key];
        }
    });

    return name;
  };

  getWidgetParameters(paramsString: string): ParamWidget {
    var response = {};
    try {
      if (typeof(paramsString) == 'string' && paramsString.length > 0) {
          return JSON.parse(paramsString);
      }
      return response;
    }
    catch (err) {
      return response;
    }
  };

  formatDate(date: string, enableTime?: boolean) {
      var parsed = moment(date).valueOf();
      if (isNaN(parsed) )
        return null;
      return moment(date).format(enableTime ? 'DD/MM/YYYY HH:mm:ss' : "DD/MM/YYYY");
  };

  removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v != "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}
