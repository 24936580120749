import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationParams } from '../models/application';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ApplicationStoreService } from '../services/application-store.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInterceptor implements HttpInterceptor {

  params: { appId: any, appParameterId: any};

  constructor(
    private store: ApplicationStoreService
  ) {
    this.params = this.store.getStateParams();
    this.store.changeStateParams.subscribe(params => {
      this.params = params;
    })
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.params && this.params.appParameterId) {
      request = ApplicationInterceptor.addParams(request, this.params);
    }
    return next.handle(request);
  }

  private static addParams(request: HttpRequest<any>, args: { appId: any, appParameterId: any}) {
    if(request.method == 'GET'){
      return request.clone({
        setParams: {
          app_param_id: args.appParameterId,
          ...request.params
        },
      });
    } if (request.method == 'POST'){
      return request.clone({
        setParams: {
          app_param_id: args.appParameterId,
          ...request.params
        },
      })
    } else {
      return request.clone();
    }
  }
}
