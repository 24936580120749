import { EventEmitter, Injectable, Output } from '@angular/core';
import { OptionsTabs, Tabs } from '../models/tab';

@Injectable({
  providedIn: 'root'
})
export class CartoafTabService {

  tabs: Tabs = {};
  active_tab: string;

  @Output() changeTabs = new EventEmitter<Tabs>()
  @Output() changeActiveTab = new EventEmitter<string>()

  constructor() { }

  push(key: string, content: OptionsTabs, active: boolean = true) {
    this.tabs[key] = content;
    sessionStorage.setItem('cartoafTabs', JSON.stringify(Object.keys(this.tabs)));
    this.changeTabs.next(this.tabs);
    if(active) {
      this.setActiveTab(key);
    }
  };

  removeTab(key: string) {
    if (this.hasTab(key)) {
      var isActive = (key == this.getActiveTab());

      delete this.tabs[key];

      var keys = Object.keys(this.tabs),
        lastKey = keys[keys.length-1];

      if (isActive) {
        this.setActiveTab(lastKey);
      }
    }
    sessionStorage.setItem('cartoafTabs', JSON.stringify(Object.keys(this.tabs)));
    this.changeTabs.next(this.tabs);
  };

  hasTab(key: string) {
    return (key in this.tabs);
  };

  getTab(key: string) {
    return (this.hasTab(key)) ? this.tabs[key] : null;
  };

  getTabs() {
    return this.tabs;
  };

  setActiveTab(key: string, emit_event: boolean = true) {
    this.active_tab = key;
    if (emit_event) {
      this.changeActiveTab.next(this.active_tab)
      sessionStorage.setItem('cartoafActiveTab', this.active_tab);
    }
  }

  getActiveTab() {
    return this.active_tab;
  }

  clearTabs(type: string | null) {
    if(type) {
      this.tabs = Object.keys(this.tabs).filter(key => key.split('_')[0] != type).reduce((res, key) => (res[key] = this.tabs[key], res), {});
    } else {
      this.tabs = Object.keys(this.tabs).filter(key => key == 'home').reduce((res, key) => (res[key] = this.tabs[key], res), {});
    }
    sessionStorage.setItem('cartoafTabs', JSON.stringify(Object.keys(this.tabs)));
    this.changeTabs.next(this.tabs);
    let keys = Object.keys(this.tabs);
    if(keys.length > 0) {
      let lastKey = keys[keys.length-1];
      this.setActiveTab(lastKey);
    }
  }

  size() {
    return Object.keys(this.tabs).length;
  };
}
