import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';


@Component({
  selector: '[notification-toast-component]',
  styles: [],
  template: `
  <div class="toast {{state.value === 'inactive' ? '' : 'show'}}" role="alert" aria-live="assertive" aria-atomic="true" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="toast-header" *ngIf="title">
      <!-- <img src="..." class="rounded mr-2" alt="..."> -->
      <strong class="mr-auto" [class]="options.titleClass" [attr.aria-label]="title">{{ title }}</strong>
      <small *ngIf="options.payload?.created_at">{{options.payload?.created_at | amTimeAgo}}</small>
      <button *ngIf="options.closeButton" (click)="remove()" type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="message && options.enableHtml" class="toast-body" role="alert"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" class="toast-body" role="alert"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('active', style({
        opacity: 1,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class NotificationToastComponent extends Toast {

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}