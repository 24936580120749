import { Injectable, Injector } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentService } from '../services/environment.service';
import { AuthKeycloakService } from '../services/auth-keycloak.service';
import { AuthCartoAfService } from '../services/auth-cartoaf.service';
import { AuthOidcService } from '../services/auth-oidc.service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthInitService {

  service: any;

  constructor(
    private keycloak: KeycloakService,
    private oidc: OAuthService,
    private environmentService: EnvironmentService,
    private injector: Injector
  ) { }

  init() {
    let promise: Promise<boolean>;
    // console.log(this.environmentService.environment.auth_provider)
    switch(this.environmentService.environment.auth_provider) {
      case 'keycloak':
        this.service = this.injector.get(AuthKeycloakService);
        promise = this.keycloak.init({
          config: {
            url: this.environmentService.environment.keycloak.url,
            realm: this.environmentService.environment.keycloak.realm,
            clientId: this.environmentService.environment.keycloak.clientId
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            responseMode: 'query',
            onLoad: this.environmentService.environment.keycloak.onLoad || 'login-required',
            checkLoginIframe: false,
          }
        });
        break;
        case 'oidc':
          this.service = this.injector.get(AuthOidcService);
          const config: AuthConfig = {
            issuer: this.environmentService.environment.keycloak.url + '/realms/' + this.environmentService.environment.keycloak.realm,
            redirectUri: this.environmentService.environment.keycloak.redirectUri,
            clientId: this.environmentService.environment.keycloak.clientId,
            responseType: 'code',
            scope: 'openid profile email',
            strictDiscoveryDocumentValidation: false,
            logoutUrl: this.environmentService.environment.keycloak.postLogoutRedirectUri,
            disableAtHashCheck: true,
          };
          this.oidc.configure(config);
          this.oidc.setStorage(localStorage)
          promise = this.oidc.loadDiscoveryDocumentAndTryLogin().then(value => {
            this.oidc.setupAutomaticSilentRefresh();
            return value
          })
          break;
      default:
        this.service = this.injector.get(AuthCartoAfService);
        promise = new Promise((resolve)=> resolve(true))
    }
    return promise;
  } 
}


