import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { IAuthService } from '../interfaces/i-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthOidcService implements IAuthService {

  isUserLogged = new BehaviorSubject<boolean>(false);

  constructor(
    private oauth: OAuthService
  ) {}

  getJwtToken() {
    return this.oauth.getAccessToken();
  }

  getID() {
    throw new Error('Method not implemented.');
  }

  envMessageToString() {
    throw new Error('Method not implemented.');
  }

  resetPassword(userId: string, password: string) {
    throw new Error('Method not implemented.');
  }

  tempPassword(userId: string) {
    throw new Error('Method not implemented.');
  }

  login(username: string, password: string) {
    throw new Error('Method not implemented.');
  }

  redirectToLoginPage() {
    this.oauth.initCodeFlow();
  }

  getUsername() {
    const claims = this.oauth.getIdentityClaims();
    return claims ? claims['preferred_username'] : null;
  }

  getUserProfile(): any {
    const raw_profile = this.oauth.getIdentityClaims();

    let profile = {
      username: raw_profile['preferred_username'],
      email: raw_profile['email'],
      firstName: raw_profile['given_name'],
      lastName: raw_profile['family_name']
    }
    
    return profile;
  }

  getProperty(prop: string) {
    const claims = this.oauth.getIdentityClaims();
    return claims[prop] || undefined
  }

  async isLoggedIn() {
    const isLogged = this.oauth.hasValidAccessToken();
    this.isUserLogged.next(isLogged);
    return isLogged;
  }

  logout(): void {
    const idToken = this.oauth.getIdToken();
    alert(idToken)
    this.oauth.revokeTokenAndLogout({
      idTokenHint: idToken,
      postLogoutRedirectUri: 'http://localhost:4200/#/welcome/logout',
    });
    this.isUserLogged.next(false);
  }

}
