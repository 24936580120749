import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EventMessage } from '../models/notifications';
import { ApiService } from './api.service';
import { ApplicationEventsService } from './application-events.service';
import { ApplicationStoreService } from './application-store.service';
import { EnvironmentService } from './environment.service';
import { EventsWsService } from './events-ws.service';
import { RendererService } from './renderer.service';
import { IAuthService } from '../interfaces/i-auth.service';

@Injectable({
  providedIn: 'root'
})
export class EventsManagerService {

  subscribeMessages: Subscription;
  cfg: string = "";
  user_id: number = null;

  events = new BehaviorSubject<EventMessage[]>([])

  messages_unreaded: EventMessage[] = [];
  messages_general: EventMessage[] = [];
  messages_readed: EventMessage[] = [];

  isLogged = false;

  constructor(
    private toastr: ToastrService,
    private eventsService: EventsWsService,
    private env: EnvironmentService,
    @Inject('IAuthService') private auth: IAuthService,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private router: Router,
    private appEvents: ApplicationEventsService,
    private store: ApplicationStoreService,
    private renderer: RendererService
  ) { 
    this.cfg = this.env.environment.restServer.split('/').reverse()[0];
    if(this.env.environment.wsServer && this.env.environment.wsServer != "")
      this.auth.isUserLogged.subscribe(isLogged => {
        if(isLogged && !this.isLogged) {
          this.isLogged = !this.isLogged
          this.init()
          this.user_id = this.auth.getID();
        } else if(!isLogged && this.isLogged) {
          this.isLogged = !this.isLogged
          this.stop()
          this.user_id = null;
        }
      })
  }

  init() {
    this.eventsService.connect({reconnect: true});
    this.subscribeToMessages();
  }

  stop() {
    this.unsubscribeFromMessages();
    this.eventsService.close();
  }

  subscribeToMessages() {
    this.subscribeMessages = this.eventsService.messages$.subscribe((message) => {
      if(typeof message == 'string' && message == 'ping') {
        this.eventsService.sendMessage("pong");
        return;
      } else if (message.key == 'all' || message.key == this.cfg || message.key == `${this.cfg}-${this.user_id}`){
        this.toastr.show(message.description, message.name, {
          closeButton: true,
          timeOut: 8000,
          progressBar: true,
          tapToDismiss: false,
          enableHtml: true,
          payload: {
            status: message.status,
            modified_at: message.modified_at,
            created_at: message.created_at
          }
        }).onTap.subscribe(() => {
          this.notificationTap(message);
        });
        this.checkReloadClass(message);
        this.getEvents();
      }
    })
  }

  unsubscribeFromMessages() {
    this.subscribeMessages.unsubscribe();
    this.subscribeMessages = null;
  }

  getEvents() {
    this.api.getEvents().subscribe((events: EventMessage[]) => {
      this.messages_readed = events.filter(el => el.readed);
      this.messages_general = events.filter(el => (el.key == 'all' || el.key == this.cfg) && !el.readed);
      this.messages_unreaded = events.filter(el => el.key == `${this.cfg}-${this.user_id}` && !el.readed);
      this.events.next(events);
    })
  }

  archiveEvent(id: number, archived: boolean) {
    this.api.archiveEvent(id, archived).subscribe(response => {
      if(response.id) {
        this.snackBar.open(archived ? "Notifica archiviata" : "Notifica ripristinata", null, {duration: 2000, verticalPosition: 'top'});
      }
      this.getEvents();
    })
  }

  changeStateAllEvent(archived: boolean) {
    this.api.changeStateAllEvent(archived).subscribe(response => {
      if(response.length > 0) {
        this.snackBar.open(`Tutte le notifiche sono state ${archived ? 'archiviate' : 'ripristinate'}`, null, {duration: 2000, verticalPosition: 'top'});
      }
      this.getEvents();
    })
  }

  notificationTap(notification: EventMessage) {
    if(notification.path) {
      let array = notification.path.split('/');
      const href: string =  location.href;
      const baseUrl: string = href.split('#')[0];
      let url: string = null;
      let params = this.store.getStateParams();
      if(params && array.length >= 3 && params.appId == Number(array[1]) && params.appParameterId == Number(array[2])) {
        switch(array.length) {
          case 5:
            this.snackBar.open(`Apertura istanza`, null, {duration: 2000, verticalPosition: 'bottom'});
            this.renderer.openInstance(array[3], array[4]);
            break;
          case 4:
            this.snackBar.open(`Apertura classe`, null, {duration: 2000, verticalPosition: 'bottom'});
            this.renderer.openClass(array[3]);
            break;
        }
      } else {
        switch(array.length) {
          case 5:
            url = baseUrl + '#' + this.router.createUrlTree(['application', array[1], array[2], 'class', array[3], 'instance', array[4]]).toString();
            break;
          case 4:
            url = baseUrl + '#' + this.router.createUrlTree(['application', array[1], array[2], 'class', array[3]]).toString();
            break;
          case 3:
            url = baseUrl + '#' + this.router.createUrlTree(['application', array[1], array[2]]).toString();
            break;
        }
        if(url){
          window.open(url, '_blank').sessionStorage.clear();
        } else {
          this.snackBar.open(`Nessun azione disponibile`, null, {duration: 2000, verticalPosition: 'bottom'});
        }
      }
    } else {
      this.snackBar.open(`Nessun azione disponibile`, null, {duration: 2000, verticalPosition: 'bottom'});
    }
  }

  checkReloadClass(notification: EventMessage) {
    if(notification.path) {
      let array = notification.path.split('/');
      this.appEvents.onInstanceSet.next({class_id: array[3] || 0, instance_id: array[4] || 0})
    }
  }
  
}
