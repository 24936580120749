import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, timeout } from 'rxjs/operators';
import { ApplicationStoreService } from './application-store.service';
import * as moment from 'moment';
import { EnvironmentService } from './environment.service';
import { UtilsService } from './utils.service';
import { OptionsEvent } from '../models/calendar';
import { ClassList, ClassSettings, Filter, Instance, PropertiesCSV } from '../models/class';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe,
        private store: ApplicationStoreService,
        private environmentService: EnvironmentService,
        private utils: UtilsService
    ) { }

    base(path: string) {

        var base_path = this.environmentService.environment.restServer;
        if (path) {
            base_path += path;
        }

        return base_path;
    }

    userLogin(credentials: any) {
        return this.http.post(this.base('/admin/login'), credentials, { responseType: 'json' });
    };

    updateUser() {
        return this.http.post(this.base('/admin/updateUser'), { responseType: 'json' });
    };

    getApplications() {
        return this.http.get(this.base('/applications')).pipe(map(response => response['data']));
    };

    getApplicationDetail(app_id: string | number) {
        return this.http.get(this.base('/application?app_id=' + app_id)).pipe(map(response => response['data'][0]));
    };

    getClassDetail(class_id: string | number) {
        return this.http.get(this.base('/class?class_id=' + class_id)).pipe(map(response => response['data'][0]));
    };

    getClasses(app_id: string | number) {
        return this.http.get(this.base('/class_list?app_id=' + app_id)).pipe(map(response => response['data']));
    }

    getApplicationUsers(app_id: string | number) {
        return this.http.get(this.base('/application_users?app_id=' + app_id)).pipe(map(response => response['data']));
    }

    getClassDetailWithRows(class_id: string | number, filter: Filter) {
        for (const filterKey in filter) {
            if (typeof (filter[filterKey]) == 'object') {
                var _filter = filter[filterKey];
                if (typeof (_filter.getDate) == 'function') {
                    filter[filterKey] = this.datePipe.transform(_filter, "yyyy-MM-dd");
                } else {
                    filter[filterKey] = _filter.value;
                }
            }
        }
        let queryParams = '&filter=' + encodeURIComponent(JSON.stringify(filter));
        return this.http.get(this.base('/classwrc?class_id=' + class_id + queryParams));
    };

    getClassList(class_id: number | string, settings?: any): Observable<ClassList> {
        var queryParams = '?',
            settings = Object.assign({
                pagination: {},
                filter: {}
            }, settings);
        for (var prop in settings.pagination) {
            if (settings.pagination.hasOwnProperty(prop)) {
                queryParams += prop + '=' + settings.pagination[prop] + '&';
            }
        }
        if (typeof (settings.filter) == 'object') {
            for (const filterKey in settings.filter) {
                if (typeof (settings.filter[filterKey]) == 'object') {
                    var _filter = settings.filter[filterKey];
                    if (typeof (_filter.getDate) == 'function') {
                        settings.filter[filterKey] = this.datePipe.transform(_filter, "yyyy-MM-dd");
                    } else {
                        settings.filter[filterKey] = _filter.value;
                    }
                }
            }
        }

        queryParams += 'filter=' + encodeURIComponent(JSON.stringify(settings.filter))
        return this.http.get<ClassList>(this.base('/class/' + class_id + queryParams));
    };

    /**
     * Function to return the relative data for a widget detail
     * @param data filter parametes
     * @returns obseravable state
     */
    updateWidgetDetails(fields: {
        instance_id: string,
        class_id: string,
        field: string,
        value: string
    }) {
        return this.http.post<any>(this.base('/__update16'), fields).pipe(map(response => response['data']));
    }

    getInsanceChildrens(instance_id: string | number, class_id: string | number) {
        return [];
        //this.http.get(baseUrl + '/class?class_id=' + class_id);
    };

    setInstanceChildrens(instance_id: string | number, class_id: string | number, childrens: string | number) {
        //return this.http.post(baseUrl + '/class/' + class_id + "/" + instance_id, childrens, 'application/json');
    };

    getPropertyOptions(params: any) {
        let clearParams = this.utils.removeEmpty(params)
        clearParams = (clearParams) ? { params: clearParams } : {};
        return this.http.post(this.base('/domain'), clearParams['params']).pipe(map(response => response['data']));
    };

    getPropertyOptionsExtended(params: any) {
        let clearParams = this.utils.removeEmpty(params)
        clearParams = (clearParams) ? { params: clearParams } : {};
        return this.http.get(this.base('/domainextended'), clearParams).pipe(map(response => response['data']));
    };

    getAutocompletePropertyOptionsByDescription(property_id: string, input?: string, dependencies: any = {}, time?: number) {
        return this.http.post(this.base("/domain"), { 
            prop_id: property_id, 
            description: input, 
            ...dependencies 
        }).pipe(timeout(time));
    };

    getAutocompletePropertyOptionsByValue(property_id: string, value: string, dependencies: any = {}) {
        return this.http.post(this.base("/domain"), {
            prop_id: property_id, 
            value: value, 
            ...dependencies
        }).pipe(map(response => response['data'][0]));
    };

    getInstanceDetail(class_id: string | number, instance_id: string | number) {
        return this.http.get(this.base('/class/' + class_id + "/" + instance_id)).pipe(map(response => response['data'][0]));
    };

    setInstance(class_id: number | string, instance: Instance) {
        instance = Object.assign({}, instance);
        for (var propName in instance)
            if (typeof (instance[propName]) == 'object' && typeof (instance[propName]?.getDate) == 'function')
                instance[propName] = moment(instance[propName]).format("YYYY-MM-DD HH:mm:ss");

        return this.http.post(this.base('/class/' + class_id + "/" + instance.__id), instance, { responseType: 'json' }).pipe(map(response => response['data'][0]));
    };

    deleteInstance(class_id: string | number, instance_id: string | number) {
        return this.http.delete(this.base('/class/' + class_id + "/" + instance_id));
    };

    getActionDetails(actionId: string) {
        return this.http.get(this.base("/action"), { params: { action_id: actionId } }).pipe(map(response => response['data'][0]));
    };

    getMenuDetails(menuId: string) {
        return this.http.get(this.base("/menu"), { params: { menu_id: menuId } }).pipe(map(response => response['data']));
    };

    getActionForm(actionId: string | number, filters: any) {
        return this.http.get(this.base("/actionform"), {
            params: Object.assign({}, { action_id: actionId }, filters)
        }).pipe(map(response => response['data']));
    };

    proxy(url: string, responseType: any = null) {
        return this.http.get(this.base("/proxy"), {
            params: { url: url }, responseType: responseType
        });
    };

    getGroupProperties(classId: string | number, instanceId: string | number, groupId: string | number) {
        return this.http.get(this.base("/class/" + classId + "/" + instanceId + "/porpertygroup/" + groupId)).pipe(map(response => response['data']));
    };

    getGroupValues(classId: string | number, instanceId: string | number, groupId: string | number) {
        return this.http.get(this.base("/class/" + classId + "/" + instanceId + "/valuegroup/" + groupId)).pipe(map(response => response['data']));
    }

    /** Calendar */

    getCalendars() {
        return this.http.get(this.base("/calendars"), {
            params: {
                app_id: this.store.getApplication()?.app_id || ''
            }
        }).pipe(map(response => response['data']));
    };

    getCalendarEvents(options: OptionsEvent) {
        return this.http.get(this.base('/events'), {
            params: {
                app_id: this.store.getApplication()?.app_id || '',
                dt_from: String(options.from),
                dt_to: String(options.to),
                calendars: [options.calendars].join(",")
            }
        }).pipe(map(response => response['data']));
    }

    /** Gerarchie */

    getExpandTree(expandAction: string) {
        return this.http.get(this.base('') + expandAction).pipe(map(response => response['data']));
    }

    /** Metadata resouces */

    getMetadata(fk_entity: number) {
        return this.http.get(this.base('/metadata'), {
            params: {
                fk_instance: fk_entity
            }
        }).pipe(map(response => response['data']));
    }

    getMetadataList(app_id: any) {
        return this.http.get(this.base('/metadata_list?app_id=' + app_id)).pipe(map(response => response['data']));
    }

    setMetadata(data: FormData) {
        return this.http.post(this.base('/set_metadata'), data).pipe(map(response => response['data']));
    }

    deleteMetadata(id: number, term: string) {
        return this.http.post(this.base('/delete_metadata'), { id, term })
    }

    /** Queue api */

    downloadMassive(path:string, files: String[]) {
        const formData = new FormData
        formData.append('path', path)
        formData.append('files', files.join(','))
        const parts = this.environmentService.environment.restServer.split('/')
        formData.append('cfg', parts[parts.length-1])
        return this.http.post(this.base('/queue_s3_massive_download'), formData)
    }

    deleteMassive(path:string, files: String[]) {
        const formData = new FormData
        formData.append('path', path)
        formData.append('files', files.join(','))
        const parts = this.environmentService.environment.restServer.split('/')
        formData.append('cfg', parts[parts.length-1])
        return this.http.post(this.base('/queue_s3_massive_delete'), formData)
    }

    uploadMassiveCSVExcel(formData: FormData) {
        return this.http.post(this.base('/s3-csv-massive-upload'), formData)
    }

    exportMassiveCsv(fk_class: string | number, classSettings: ClassSettings, propertiesCSV: PropertiesCSV[], delimiter: string) {
        const formData = new FormData();
        formData.append('fk_class', String(fk_class))
        formData.append('classSettings', JSON.stringify(classSettings))
        formData.append('propertiesCSV', JSON.stringify(propertiesCSV))
        formData.append('delimiter', delimiter)
        const parts = this.environmentService.environment.restServer.split('/')
        formData.append('cfg', parts[parts.length-1])
        return this.http.post(this.base('/queue_export_csv_massive'), formData)
    }

    /** Events api */

    getEvents() {
        const cfg = this.environmentService.environment.restServer.split('/').reverse()[0];
        return this.http.get(this.base('/cartoevents?cfg=' + cfg)).pipe(map(response => response['data']));
    }

    archiveEvent(id: number, archived: boolean) {
        return this.http.post(this.base('/change_state_cartoevent'), {id: id, readed: String(archived)}).pipe(map(response => response['data'][0]));
    }

    changeStateAllEvent(archived: boolean) {
        const cfg = this.environmentService.environment.restServer.split('/').reverse()[0];
        return this.http.post(this.base('/change_state_all_personal_cartoevents'), {cfg: cfg, readed: String(archived)}).pipe(map(response => response['data']));
    }
    
    sqlExecute(__sqlexecute: string, params: any = {}) {
        return this.http.post(this.base('/sqlexecute'), {__sqlexecute, ...params}).pipe(map(response => response['data']));
    }

    getSorveglianza(zona_all: string) {
        const baseUrl = this.base('').split('/').reverse().slice(1).reverse().join('/')
        return this.http.get(baseUrl + '/meteo/__getSorveglianza?zona_all=' + zona_all).pipe(map(response => response['data']));
    }
}
