import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { IAuthService } from '../interfaces/i-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(
    @Inject('IAuthService') private auth: IAuthService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ){
    if(await this.auth.isLoggedIn()) {
      return true;
    } else {
      this.auth.redirectToLoginPage();
      return false;
    }
  }

}
