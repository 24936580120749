import { EventEmitter, Injectable, Output } from '@angular/core';
import { CalculationEvent } from '../models/application-events';

@Injectable({
  providedIn: 'root'
})
export class ApplicationEventsService {

  @Output() onInstanceSet = new EventEmitter<{class_id: string | number, instance_id: string | number}>();

  @Output() onInstanceDelete = new EventEmitter<{class_id: string | number}>();

  @Output() onValueInstanceUpdate = new EventEmitter<{class_id: string | number, instance_id: string | number, prop_column?: string}>()

  @Output() onCalculationRequest = new EventEmitter<CalculationEvent>();
  
  constructor() { }
}
