import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultModalComponent } from 'src/app/application/components/modals/default-modal/default-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OldAlertService {

  constructor(
    private modalService: NgbModal,
    private router: Router
  ) { }

  showAlert(type: 'info' | 'error' | 'warning', message: string) {
    
    if (typeof message != 'string' || message.trim().length == 0)
      return;

    var headerIcon = 'fa fa-info';
    var headerText = 'Informazione';

    switch (type) {
      case 'info':
        headerIcon = 'fa fa-info-circle';
        headerText = 'Informazione';
        break;
      case 'error':
        if (message == "Credentials are required to access this resource.") {
          this.router.navigate(['/']);
          return;
        }

        headerIcon = 'fa fa-exclamation-circle';
        headerText = 'Errore';

        // console.error(headerText+": "+message);
        break;
      case 'warning':
        headerIcon = 'fa fa-exclamation-circle';
        headerText = 'Attenzione';
        break;
    }

    const modalRef = this.modalService.open(DefaultModalComponent, {
      windowClass: 'center-modal',
    });
    modalRef.componentInstance.config = {
      headerIcon: headerIcon,
      headerText: headerText,
      bodyText: message,
      buttons: [
        {
          'id': '1',
          'icon': 'fa fa-check',
          'label': 'OK'
        }
      ]
    };
  };
}
