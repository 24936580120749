import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ApplicationInterceptor } from './core/interceptors/application.interceptor';
import { ResponseErrorInterceptor } from './core/interceptors/response-error.interceptor';
import { HttpCancelInterceptor } from './core/interceptors/http-cancel.interceptor';
import { AppInitService } from './core/providers/app-init.service';
import { MarkdownModule } from 'ngx-markdown';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CacheCartoafInterceptor } from './core/interceptors/cache-cartoaf.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NotificationToastComponent } from './shared/components/notification-toast/notification-toast.component';
import moment from 'moment';
import { EventsManagerService } from './core/services/events-manager.service';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { AuthInitService } from './core/providers/auth-init.service';
import { KeycloakAngularModule } from 'keycloak-angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ApiService } from './core/services/api.service';

registerLocaleData(localeIt);
moment.locale('it-IT')

export function init_app(app: AppInitService, auth: AuthInitService) {
  return () => app.init().then(() => auth.init());
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    MatSnackBarModule,
    MarkdownModule.forRoot({
      loader: HttpClient
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: NotificationToastComponent
    }),
    KeycloakAngularModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    DatePipe,
    EventsManagerService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService, AuthInitService],
      multi: true
    },
    {
      provide: 'IAuthService',
      useFactory: (appInitService: AuthInitService) => appInitService.service,
      deps: [AuthInitService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheCartoafInterceptor,
      deps: [ApiService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationInterceptor,
      multi: true
    },
    { 
      provide: TINYMCE_SCRIPT_SRC, 
      useValue: './tinymce/tinymce.min.js' ,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
