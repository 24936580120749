import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.auth.isLoggedIn()) {
      return true;
    } else {
      let queryParams = route.queryParams
      // return this.router.parseUrl(`/welcome/login?redirectURL=${state.url}`);
      return this.router.navigate(['/welcome/login'], {
        queryParams: {
          redirectURL: state.url.split('?')[0],
          ...queryParams
        }
      })
    }
  }

}
