import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheEntry, MAX_CACHE_AGE } from '../models/cache-entry';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  cacheMap = new Map<string, CacheEntry>();

  allowCache: boolean = true;

  constructor(
    private auth: AuthService
  ) {
    let sessionCache = JSON.parse(sessionStorage.getItem('cartoaf-cache'));
    if(sessionCache && sessionCache.length > 0) {
      this.cacheMap = new Map<string, CacheEntry>(sessionCache);
    }
    if(this.auth.getUsername() == 'admin') {
      let savedSettings = JSON.parse(sessionStorage.getItem('cartoaf-allowCache'))
      if(typeof savedSettings == 'boolean') {
        this.allowCache = savedSettings
      }
    }
  }

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const entry = this.cacheMap.get(req.urlWithParams);
    if (!entry) {
      return null;
    }
    const isExpired = (Date.now() - entry.entryTime) > MAX_CACHE_AGE;
    if(isExpired) {
      this.cacheMap.delete(entry.url);
      sessionStorage.setItem('cartoaf-cache', JSON.stringify([...this.cacheMap]));
      return null;
    } else {
      return entry.response;
    }
  }
  
  put(req: HttpRequest<any>, res: HttpResponse<any>): void {
    const entry: CacheEntry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
    this.cacheMap.set(req.urlWithParams, entry);
    sessionStorage.setItem('cartoaf-cache', JSON.stringify([...this.cacheMap]));
  }

  saveAdminSettings(value: boolean) {
    if(!value) {
      sessionStorage.removeItem('cartoaf-cache');
      this.cacheMap.clear();
    }
    sessionStorage.setItem('cartoaf-allowCache', JSON.stringify(value));
  }
}
