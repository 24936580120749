import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  /** config.js file that will contain out environment variables */
  private readonly CONFIG_URL = 'assets/config/env.json';
  private config$: Observable<any>;
  
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
    ) { }
  
    /**
     * Method for loading configuration
     */
    public init() {
      this.config$ = this.http.get(this.CONFIG_URL)
        .pipe(
          shareReplay(1),
          catchError(err => of(environment))
        );

      this.environmentService.setEnvironment(this.config$);
      return this.config$.toPromise();
  }
}
