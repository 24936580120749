import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApplicationDetail } from 'src/app/core/models/application';
import { Action } from '../models/action';
import { BackendCalendar } from '../models/calendar';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStoreService {

  application?: ApplicationDetail;
  applicationCalendars?: BackendCalendar[];
  copyrightDate = new Date();
  stateParams: { appId: number, appParameterId: number};

  users: any[] = [];
  classes: any[] = [];

  @Output() changeApplication = new EventEmitter<ApplicationDetail>();
  @Output() changeStateParams = new EventEmitter<{ appId: number, appParameterId: number}>();

  constructor() {}

  setApplication(value: ApplicationDetail) {
    this.application = value;
    this.changeApplication.next(this.application);
  }

  getApplication() {
    return this.application;
  }

  setApplicationCalendars(value: BackendCalendar[]) {
    this.applicationCalendars = value;
  }

  getApplicationCalendars() {
    return this.applicationCalendars;
  }

  getCopyrightDate() {
    return this.copyrightDate;
  }

  setStateParams(appId: number, appParameterId: number) {
    this.stateParams = {appId, appParameterId};
    this.changeStateParams.next(this.stateParams);
  }

  getStateParams() {
    return this.stateParams;
  }

  setClasses(value: any[]) {
    this.classes = value;
  }

  getClasses() {
    return this.classes || [];
  }

  setUsers(value: any[]) {
    this.users = value;
  }

  getUsers() {
    return this.users || [];
  }

  detailAction(actionObj: Action) {
    var win;
    switch (actionObj.target) {
      case "panel":
      case "tab":
        win = this.openWindow(actionObj.url, actionObj.action_id);
        win.focus();
        break;
      case "window":
        win = this.openWindow(actionObj.url, actionObj.action_id, 'toolbar=0,status=0,width=800,height=500');
        win.focus();
        break;
      default:
        break;
    }
  };

  resetApplication() {
    this.application = undefined;
    this.applicationCalendars = [];
    this.stateParams = undefined;
    this.users = [];
    this.classes = [];
  }
  
  openWindow(url: string, name: number | string, features?: string) {
    let windows: any = {}
    windows[name] = window.open(url, name as string, features);
    return windows[name];
  }
}
