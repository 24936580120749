import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {

  private cancelPendingRequests$ = new Subject<HttpErrorResponse>()

  constructor() { }

  /** Cancels all pending Http requests. */
  public cancelPendingRequests() {
    this.cancelPendingRequests$.next(new HttpErrorResponse({error: "canceled"}))
  }

  public onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable()
  }
}
