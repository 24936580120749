import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject } from 'rxjs';
import { IAuthService } from '../interfaces/i-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthKeycloakService implements IAuthService {

  isUserLogged = new BehaviorSubject<boolean>(false);
  
  constructor(
    private keycloakService: KeycloakService,
    private environmentService: EnvironmentService
  ) {}

  getJwtToken() {
    return this.keycloakService.getKeycloakInstance().token;
  }
  getID() {
    throw new Error('Method not implemented.');
  }
  envMessageToString() {
    throw new Error('Method not implemented.');
  }
  resetPassword(userId: string, password: string) {
    throw new Error('Method not implemented.');
  }
  tempPassword(userId: string) {
    throw new Error('Method not implemented.');
  }
  
  login(username: string, password: string) {
    throw new Error('Method not implemented.');
  }

  redirectToLoginPage() {
    this.keycloakService.login();
  }

  getUsername() {
    return this.keycloakService.getUsername();
  }

  getUserProfile(): any {
    return this.keycloakService.getKeycloakInstance().profile
  }

  async isLoggedIn() {
    let auth = await this.keycloakService.isLoggedIn();
    this.isUserLogged.next(auth);
    return auth;
  }

  logout(): void {
    this.keycloakService.logout(this.environmentService.environment.keycloak.postLogoutRedirectUri);
    this.isUserLogged.next(false);
  }
}
