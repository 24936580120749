import { Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private requests = 0;
  @Output() display = new BehaviorSubject<boolean>(false);
  
  constructor() { }

  get totalRequests() {
    return this.requests;
  }

  show() {
    this.display.next(true);
  }

  hide() {
    this.display.next(false);
  }

  increment() {
    this.requests++;
  }

  decrement() {
    if(this.requests > 0){
      this.requests--;
    }
  }

  reset() {
    this.requests = 0;
    this.hide();
  }

}
