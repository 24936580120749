import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of, Observable, BehaviorSubject } from "rxjs";
import { catchError, mapTo, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Jwt } from "src/app/core/models/jwt";
import { OldAlertService } from "./old-alert.service";
import { EnvironmentService } from "./environment.service";
import { CartoAfPayload } from "../models/auth";
import { IAuthService } from "../interfaces/i-auth.service";

const jwtService = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthCartoAfService implements IAuthService {
  private readonly JWT_TOKEN = "CARTOAF_JWT_TOKEN";
  
  isUserLogged = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    readonly router: Router,
    private alertService: OldAlertService,
    private environmentService: EnvironmentService
  ) {}

  /**
   *
   * @param userid
   * @param password
   * si fa un POST per fare la login, solo se l'api risponde in maniera corretta si costruisce il token con i parametri passati
   */
  login(userid: string, password: string): Observable<boolean> {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    return this.http
      .post<any>(
        `${this.environmentService.environment.restServer}/admin/login`,
        { userid, password },
        { headers }
      )
      .pipe(
        tap((tokens) => this.doLoginUser(tokens)),
        mapTo(true),
        catchError((error) => {
          this.alertService.showAlert('error', error.error);
          return of(false);
        })
      );
  }

  /**
   * logout, si passa alla pagina login
   */
  logout() {
    this.doLogoutUser();
    this.router.navigate(["/welcome/login"]);
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      const token: CartoAfPayload = jwtService.decodeToken(this.getJwtToken());
      if(token){
        let now = Date.now();
        let expired_token = token.exp * 1000 < now;
        if(!expired_token){
          this.isUserLogged.next(true);
          resolve(true);
        } else {
          this.doLogoutUser();
          resolve(false);
        }
      } else {
        this.isUserLogged.next(false);
        resolve(false);
      }
    })
    
  }

  redirectToLoginPage() {
    console.log("redirect")
    this.logout();
  }

  getJwtToken() {
    return localStorage.getItem(`${this.JWT_TOKEN}_${this.envMessageToString()}`) || undefined;
  }

  /**
   * si decodifica il token per recuperare lo username
   */
  getUsername(): string {
    const token: CartoAfPayload = jwtService.decodeToken(this.getJwtToken());
    if(token?.user_id) {
      return token.user_id;
    } else {
      return 'username non definito'
    }
  }

  getUserProfile() {
    const token: CartoAfPayload = jwtService.decodeToken(this.getJwtToken());
    return token;
  }

  /**
   * si decodifica il token per recuperare l'id
   */
  getID(): number {
    const token: CartoAfPayload = jwtService.decodeToken(this.getJwtToken());
    if(token?.__id) {
      return token?.__id;
    } else {
      return null
    }
  }

  /**
   *
   * @param username
   * @param tokens
   * si passa il token all'interno di un altro metodo
   */
  private doLoginUser(tokens: Jwt) {
    this.storeTokens(tokens);
    this.isUserLogged.next(true);
  }

  private doLogoutUser() {
    this.removeTokens();
    this.isUserLogged.next(false);
  }

  /**
   *
   * @param jwt
   * questo jwt si setta all'interno del localstorage
   */
  private storeTokens(jwt: Jwt) {
    localStorage.setItem(`${this.JWT_TOKEN}_${this.envMessageToString()}`, jwt.token);
  }

  private removeTokens() {
    localStorage.removeItem(`${this.JWT_TOKEN}_${this.envMessageToString()}`);
  }

  envMessageToString() {
    let message: string = this.environmentService.environment.welcome.message;
    if(message && message.length > 0) {
      return message.toUpperCase().replace(' ', '_');
    } else {
      return 'TEST';
    }
    
  }

  resetPassword(userId: string, password: string) {
    return this.http.post<any>(`${this.environmentService.environment.restServer}/admin/resetPassword`, {
      userid: userId,
      password: password
    })
  }

  tempPassword(userId: string) {
    return this.http.post<any>(`${this.environmentService.environment.restServer}/admin/tempPassword`, {
      userid: userId
    })
  }
}

