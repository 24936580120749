import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '../services/http-cancel.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {

  constructor(
    private httpCancelService: HttpCancelService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
  }
}
