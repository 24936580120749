import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from '../services/cache.service';

const CACHABLE_URL = "/domain";
@Injectable()
export class CacheCartoafInterceptor implements HttpInterceptor {

  constructor(
    private cache: CacheService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRequestCachable(request)) { 
      return next.handle(request); 
    }
    const cachedResponse = this.cache.get(request);
    if (cachedResponse !== null) {
      return of(new HttpResponse(cachedResponse));
    }
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.cache.put(request, event); 
        }
      })
    );
  }
  private isRequestCachable(request: HttpRequest<any>) {
    return (request.method === 'GET') && (request.url.indexOf(CACHABLE_URL) > -1) && this.cache.allowCache;
  }
}
